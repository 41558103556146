export const woodData = [
  {
    id: "blackIceFW3",
    img: "images/fitting/BLACKICE-FW(3).Webp",
    title: "BLACK ICE - FW(#3)",
    link: "blackIceFW3",
    price: "600,000",
    src: "images/gtdProduct/blackIceFW3",
  },
  {
    id: "blackIceFW5",
    img: "images/fitting/BLACKICE-FW(5).Webp",
    title: "BLACK ICE - FW(#5)",
    link: "blackIceFW5",
    price: "600,000",
    src: "images/gtdProduct/blackIceFW5",
  },
  {
    id: "blackIceUT19",
    img: "images/fitting/BLACKICE-UT(19).Webp",
    title: "BLACK ICE - UT(19°)",
    link: "blackIceUT19",
    price: "500,000",
    src: "images/gtdProduct/blackIceUT19",
  },
  {
    id: "blackIceUT21",
    img: "images/fitting/BLACKICE-UT(21).Webp",
    title: "BLACK ICE - UT(21°)",
    link: "blackIceUT21",
    price: "500,000",
    src: "images/gtdProduct/blackIceUT21",
  },
  {
    id: "blackIceUT24",
    img: "images/fitting/BLACKICE-UT(24).Webp",
    title: "BLACK ICE - UT(24°)",
    link: "blackIceUT24",
    price: "500,000",
    src: "images/gtdProduct/blackIceUT24",
  },
];
