export const wedgeData = [
  {
    id: "doubleForged",
    img: "images/fitting/DoubleForged.Webp",
    title: "Double Forged",
    link: "doubleForged",
    price: "350,000",
    src: "images/gtdProduct/doubleForged",
  },
];
