export const bagData = [
  {
    id: "STAND-BAG(BLACK)",
    img: "images/accessories/STAND-BAG(Black).Webp",
    title: "STAND BAG (BLACK)",
    link: "STAND-BAG(BLACK)",
    price: "579,000",
    src: "images/gtdProduct/STANDBAG(BLACK)",
  },
  {
    id: "STAND-BAG(WHITE)",
    img: "images/accessories/STAND-BAG(White).Webp",
    title: "STAND BAG (WHITE)",
    link: "STAND-BAG(WHITE)",
    price: "579,000",
    src: "images/gtdProduct/STANDBAG(WHITE)",
  },
  {
    id: "BOSTON-BAG(Black)",
    img: "images/accessories/BOSTON-BAG(Black).Webp",
    title: "BOSTON BAG (BLACK)",
    link: "BOSTON-BAG(Black)",
    price: "339,000",
    src: "images/gtdProduct/BOSTON-BAG(Black)",
  },
  {
    id: "BOSTON-BAG(White)",
    img: "images/accessories/BOSTON-BAG(White).Webp",
    title: "BOSTON BAG (WHITE)",
    link: "BOSTON-BAG(White)",
    price: "339,000",
    src: "images/gtdProduct/BOSTON-BAG(White)",
  },
];
