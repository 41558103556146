export const ironData = [
  {
    id: "GT01",
    img: "images/fitting/GT-01.Webp",
    title: "GT - 01",
    link: "GT01",
    price: "2,400,000",
    src: "images/gtdProduct/GT01",
  },
];
